<div class="row">
  <div class="col-md-3">
    <span class="hipster img-fluid rounded"></span>
  </div>

  <div class="col-md-9">
    <h1 class="display-4" jhiTranslate="home.title">Welcome, Java Hipster!</h1>

    <p class="lead" jhiTranslate="home.subtitle">This is your homepage</p>

    <div [ngSwitch]="isAuthenticated()">
      <div class="alert alert-success" *ngSwitchCase="true">
        <span id="home-logged-message" *ngIf="account" jhiTranslate="home.logged.message" [translateValues]="{ username: account.login }"
          >You are logged in as user "{{ account.login }}".</span
        >
      </div>

      <div class="alert alert-warning" *ngSwitchCase="false">
        <span jhiTranslate="global.messages.info.authenticated.prefix">If you want to </span>
        <a class="alert-link" (click)="login()" jhiTranslate="global.messages.info.authenticated.link">sign in</a>
      </div>
      <div class="alert alert-warning" *ngSwitchCase="false">
        <span jhiTranslate="global.messages.info.register.noaccount">You don't have an account yet?</span>&nbsp;
        <a class="alert-link" routerLink="account/register" jhiTranslate="global.messages.info.register.link">Register a new account</a>
      </div>
    </div>
  </div>
</div>
