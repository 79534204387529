import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Authority } from 'app/config/authority.constants';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'news',
        data: { authorities: [Authority.ADMIN], pageTitle: 'sceneRtApp.news.home.title' },
        loadChildren: () => import('./news/news.module').then(m => m.NewsModule),
      },
      {
        path: 'entertainment-person',
        data: { authorities: [Authority.ADMIN, Authority.PRO], pageTitle: 'sceneRtApp.entertainmentPerson.home.title' },
        loadChildren: () => import('./entertainment-person/entertainment-person.module').then(m => m.EntertainmentPersonModule),
      },
      {
        path: 'contact',
        data: { authorities: [Authority.ADMIN], pageTitle: 'sceneRtApp.contact.home.title' },
        loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule),
      },
      {
        path: 'entertainment-person-show-role',
        data: { authorities: [Authority.ADMIN, Authority.PRO], pageTitle: 'sceneRtApp.entertainmentPersonShowRole.home.title' },
        loadChildren: () =>
          import('./entertainment-person-show-role/entertainment-person-show-role.module').then(m => m.EntertainmentPersonShowRoleModule),
      },
      {
        path: 'entertainment-role',
        data: { authorities: [Authority.ADMIN, Authority.PRO], pageTitle: 'sceneRtApp.entertainmentRole.home.title' },
        loadChildren: () => import('./entertainment-role/entertainment-role.module').then(m => m.EntertainmentRoleModule),
      },
      {
        path: 'show',
        data: { authorities: [Authority.ADMIN, Authority.PRO], pageTitle: 'sceneRtApp.show.home.title' },
        loadChildren: () => import('./show/show.module').then(m => m.ShowModule),
      },
      {
        path: 'contact-type',
        data: { authorities: [Authority.ADMIN], pageTitle: 'sceneRtApp.contactType.home.title' },
        loadChildren: () => import('./contact-type/contact-type.module').then(m => m.ContactTypeModule),
      },
      {
        path: 'festival',
        data: { authorities: [Authority.ADMIN], pageTitle: 'sceneRtApp.festival.home.title' },
        loadChildren: () => import('./festival/festival.module').then(m => m.FestivalModule),
      },
      {
        path: 'genre',
        data: { authorities: [Authority.ADMIN], pageTitle: 'sceneRtApp.genre.home.title' },
        loadChildren: () => import('./genre/genre.module').then(m => m.GenreModule),
      },
      {
        path: 'show-genre',
        data: { authorities: [Authority.ADMIN, Authority.PRO], pageTitle: 'sceneRtApp.showGenre.home.title' },
        loadChildren: () => import('./show-genre/show-genre.module').then(m => m.ShowGenreModule),
      },
      {
        path: 'festival-show',
        data: { authorities: [Authority.ADMIN], pageTitle: 'sceneRtApp.festivalShow.home.title' },
        loadChildren: () => import('./festival-show/festival-show.module').then(m => m.FestivalShowModule),
      },
      {
        path: 'rating-attribute',
        data: { authorities: [Authority.ADMIN], pageTitle: 'sceneRtApp.ratingAttribute.home.title' },
        loadChildren: () => import('./rating-attribute/rating-attribute.module').then(m => m.RatingAttributeModule),
      },
      /* jhipster-needle-add-entity-route - JHipster will add entity modules routes here */
    ]),
  ],
})
export class EntityRoutingModule {}
